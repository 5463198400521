import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import StatsCard from "./StatsCard";

// type Props = {}

const SummitStats = () => {
  return (
    <React.Fragment>
      <Stack
        className="summit-stats-component  hidden lg:flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          "& svg": {
            color: "common.white",
          },
          "& button:hover svg": {
            color: "primary.main",
          },
        }}
      >
        <Typography
          variant="caption"
          fontSize={{ xs: "11px", sm: "11px" }}
          fontWeight={900}
          lineHeight={{ xs: "100%", sm: "102%" }}
          letterSpacing={"0.1em"}
          className="uppercase text-center"
        >
          It's Time To Care About Work And Life
        </Typography>

        <Typography
          variant="h2"
          fontSize={{ xs: "38px", sm: "40px", md: "50px" }}
          lineHeight={"120.5%"}
          letterSpacing={"-0.04em"}
          className="py-8 text-center "
        >
          Here is Why
        </Typography>

        <Box className="lg:flex lg:justify-center lg:gap-10 ">
          <StatsCard
            stats="74.6%"
            info="of people said they find it hard to focus on work because of the cost-of-living crisis, economic downturn, and COVID-19 (Oyster)"
          />
          <StatsCard
            stats="53M"
            info="Americans serve as unpaid caregivers to an adult with health or functional needs. (AARP)"
          />
          <StatsCard
            stats="73%"
            info="Of the workforce have some type of current caregiving responsibilities (Harvard Business School)"
          />
          {/* <StatsCard
            stats="104K"
            info="Of the workforce missed work in October 2022 due to childcare shortages (Census Bureau)"
          /> */}
        </Box>
      </Stack>

      {/* Tab & Mobile */}

      <Stack
        className="summit-stats-component-tabmobile lg:hidden flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          "& svg": {
            color: "common.white",
          },
          "& button:hover svg": {
            color: "primary.main",
          },
        }}
      >
        <Typography
          variant="caption"
          fontSize={{ xs: "11px", sm: "11px" }}
          fontWeight={900}
          lineHeight={{ xs: "100%", sm: "102%" }}
          letterSpacing={"0.1em"}
          className="uppercase text-center"
        >
          It's Time To Care About Work And Life
        </Typography>

        <Typography
          variant="h2"
          fontSize={{ xs: "38px", sm: "40px", md: "50px" }}
          lineHeight={"120.5%"}
          letterSpacing={"-0.04em"}
          className="py-8  text-center w-[90%]"
        >
          Here is Why
        </Typography>

        <Box className="block">
          <StatsCard
            stats="74.6%"
            info="of people said they find it hard to focus on work because of the cost-of-living crisis, economic downturn, and COVID-19 (Oyster)"
          />
          <StatsCard
            stats="53M"
            info="Americans serve as unpaid caregivers to an adult with health or functional needs. (AARP)"
          />
          <StatsCard
            stats="73%"
            info="Of the workforce have some type of current caregiving responsibilities (Harvard Business School)"
          />
          {/* <StatsCard
            stats="104K"
            info="Of the workforce missed work in October 2022 due to childcare shortages (Census Bureau)"
          /> */}
        </Box>
      </Stack>
    </React.Fragment>
  );
};

export default SummitStats;
