import React from "react";
import MHSubscribe from "../components/Layout/MHSubscribe";
import MHFooter from "../components/Layout/MHFooter";

const CalendlyEmbed = () => {
  return (
    <React.Fragment>
      <iframe
        title="The Care Gap Calendly"
        src="https://calendly.com/caringbloc/thecaregap"
        width="100%"
        height="800px"
        frameBorder="0"
      />

      <MHSubscribe />

      <MHFooter />
    </React.Fragment>
  );
};

export default CalendlyEmbed;
