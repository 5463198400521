import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Link, useHistory } from "react-router-dom";
import { UP_EVENTS } from "../../utils/footer-data";
import LandingEventMobile from "./LandingEventMobile";
import dayjs from "dayjs";
import MHButton from "../Common/Button/MHButton";
import { HttpResponse } from "../../models/api.interface";
import { checkInvalidArray } from "../../utils/utils";
import { ItemList, ResourceItemType } from "../../models/resources.interface";

type ResProps = {
  thumbNailImageSrc?: string;
  itemList?: string[];
  startTime?: number;
  endTime?: number;
  title?: string;
  texts?: string;
  categ?: string;
  id?: number;
  slug?: string;
  createdAt?: string;
  updatedAt?: string;
};

const LandingEvent = () => {
  const [resources, setResources] = useState<ResourceItemType[]>([]);

  var resUrl = `${process.env.REACT_APP_RES_EVENT_URL}`;

  const getResource = async () => {
    try {
      const response = await fetch(resUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let jsonData: HttpResponse<ResourceItemType[]> = await response.json();

      const formattedResources = checkInvalidArray(jsonData.data)
        ? jsonData.data.map((dataItem) => {
            const priorityList: string[] = dataItem.priorities.split(",");
            let newItemList: ItemList[] = [];

            for (const item of priorityList) {
              const newObject = dataItem.itemList.find(
                (it: any) => it.id === +item
              );

              if (newObject) {
                if (!newItemList.length) {
                  newItemList = [newObject];
                } else {
                  newItemList = [...newItemList, newObject];
                }
              }
            }

            return {
              ...dataItem,
              newItemList,
            };
          })
        : [];

      setResources(formattedResources);
      // console.log(resources);
    } catch (err) {
      console.error(err);
    }
  };

  const [noOfElement, setnoOfElement] = React.useState(2);
  const slice = resources.slice(0, noOfElement);

  React.useEffect(() => {
    getResource();
  }, []);

  let history = useHistory();

  const handleClickOpen = () => {
    history.push(`/resources/events`);
  };

  return (
    <React.Fragment>
      {/* WEB BEIGNS */}

      <Box className="py-10 h-[750px] hidden md:hidden lg:block overflow-hidden">
        <Grid
          container
          spacing={10}
          className="px-5 md:px-4 lg:px-0 py-4 mx-auto items-center"
        >
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className=" w-[630px]  h-[630px] rounded-[6px] bg-cream-100">
              <img
                src="https://res.cloudinary.com/mother-honestly/image/upload/v1688647565/8A64537D-68EA-4CA4-A408-8F5D0922DF60_aykrsa.png"
                alt="Event Image"
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="lg:ml-3 w-[455px] h-[520px]">
              <Typography
                variant="h2"
                fontSize={{ xs: "30px", sm: "38px", md: "43px" }}
                lineHeight={"120.5%"}
                letterSpacing={"-0.06em"}
                className=" text-center"
              >
                Upcoming Events
              </Typography>

              <hr className="my-8 hidden md:block w-[90%] mx-auto h-0 border-t-lilac-300" />

              {resources.length === 0 ? (
                <Typography
                  variant="body1"
                  textTransform="uppercase"
                  fontSize={{
                    xs: "11px",
                    sm: "12px",
                    md: "12px",
                  }}
                  lineHeight={{ xs: "230%", sm: "102%" }}
                  letterSpacing={"0.1em"}
                  textAlign="center"
                  className="pb-2"
                >
                  New Event coming soon
                </Typography>
              ) : null}

              <Box>
                {resources &&
                  slice.map((event, index) => (
                    <Link
                      to={`/resources/events/${event.slug}/${event.id}`}
                      className="hover:no-underline"
                    >
                      <Box className="w-fit h-[153px] flex my-6" key={index}>
                        <Box>
                          <img
                            className="w-[150px] h-[150px] rounded-l-[6px]"
                            src={event.thumbNailImageSrc}
                            alt={event.title}
                          />
                        </Box>
                        <Box className="bg-cream-100 p-3 h-[153px] w-[305px] rounded-r-[6px]">
                          {/* <Typography className="uppercase font-areaNorm text-start md:text-[11px]  leading-[150%] tracking-[0.115em] text-navy-900 ">
                        {event.date}
                      </Typography> */}
                          <Typography
                            variant="subtitle1"
                            fontSize={"11px"}
                            lineHeight={"150%"}
                            letterSpacing={"0.115em"}
                            className="uppercase text-start "
                          >
                            {dayjs(event.startTime!).format("MMMM D, h:mma")}
                          </Typography>

                          {/* <Typography className="py-2.5 font-areaSemi text-start md:text-[14px] md:leading-[180%] tracking-[0.02em] text-navy-900 ">
                        {event.title}
                      </Typography> */}
                          <Typography
                            variant="body1"
                            fontSize={"14px"}
                            lineHeight={"180%"}
                            letterSpacing={"0.02em"}
                            className="py-1 line-clamp-2 mt-2 text-start "
                          >
                            {event.title}
                          </Typography>

                          <hr className="mt-4 hidden md:block w-[98%]  h-0 border-t-gray-300" />

                          <Typography
                            variant="body1"
                            fontSize={"12px"}
                            lineHeight={"180%"}
                            letterSpacing={"0.04em"}
                            className="py-1 text-start line-clamp-1"
                          >
                            {event.newItemList
                              .map((item) => item.interestName)
                              .join(", ")}
                          </Typography>
                        </Box>
                      </Box>
                    </Link>
                  ))}
              </Box>

              <Box className="mx-auto text-center md:py-2">
                <MHButton
                  onClick={() => handleClickOpen()}
                  className="md:text-[12px] lg:text-[12px] leading-[102%] tracking-[0.05em] md:w-[165px] lg:w-[172px] md:h-[50px] lg:h-[52px] py-5"
                >
                  See All Events
                </MHButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* WEB ENDS */}

      {/* TAB BEGINS  */}

      <Box className="py-10 h-[550px] hidden md:block lg:hidden">
        <Grid container spacing={8} className=" px-12 py-4 items-center">
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="landingtool w-[350px]  h-[350px] rounded-[6px] bg-cream-100">
              <img
                src="https://res.cloudinary.com/mother-honestly/image/upload/v1670669593/image_ghzlqf.png"
                alt="Event Image"
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box
              className=" w-[255px] h-[400px]"
              justifyContent="center"
              alignItems="center"
            >
              {/* <Typography className=" font-columbia md:text-[25px] text-center  leading-[100.5%] tracking-[-0.06em] text-navy-900 ">
                Upcoming Events
              </Typography> */}
              <Typography
                variant="h2"
                fontSize={"25px"}
                lineHeight={"100.5%"}
                letterSpacing={"-0.06em"}
                className="text-center pt-5"
              >
                Upcoming Events
              </Typography>
              <hr className="my-4 hidden md:block w-[90%] mx-auto h-0 border-t-lilac-300" />
              {resources.length === 0 ? (
                <Typography
                  variant="body1"
                  textTransform="uppercase"
                  fontSize={{
                    xs: "11px",
                    sm: "11px",
                    md: "12px",
                  }}
                  lineHeight={{ xs: "230%", sm: "102%" }}
                  letterSpacing={"0.1em"}
                  textAlign="center"
                  className="py-4"
                >
                  New Event coming soon
                </Typography>
              ) : null}

              <Box>
                {slice.map((event, index) => (
                  <Link to={`/${event.slug}`} className="hover:no-underline">
                    <Box className="w-fit h-[153px] flex" key={index}>
                      <Box>
                        <img
                          className="w-[120px] h-[120px] rounded-l-[6px]"
                          src={event.thumbNailImageSrc}
                          alt={event.title}
                        />
                      </Box>
                      <Box className="bg-cream-100 p-3 h-[120px] w-[350px] rounded-r-[6px]">
                        {/* <Typography className="uppercase font-areaNorm text-start md:text-[11px]  leading-[150%] tracking-[0.115em] text-navy-900 ">
                        {event.date}
                      </Typography> */}
                        <Typography
                          variant="subtitle1"
                          fontSize={"11px"}
                          lineHeight={"150%"}
                          letterSpacing={"0.115em"}
                          className="uppercase text-start "
                        >
                          {dayjs(event.startTime!).format("MMMM D, h:mma")}
                        </Typography>

                        {/* <Typography className="py-1 font-areaSemi text-start md:text-[14px] md:leading-[180%] tracking-[0.02em] text-navy-900 ">
                        {event.title}
                      </Typography> */}
                        <Typography
                          variant="body1"
                          fontSize={"14px"}
                          lineHeight={"180%"}
                          letterSpacing={"0.02em"}
                          className="py-1 line-clamp-2 text-start "
                        >
                          {event.title}
                        </Typography>

                        <hr className="mt-1 hidden md:block w-[98%]  h-0 border-t-gray-300" />

                        {/* <Typography className="py-1 font-areaSemi text-start md:text-[12px] md:leading-[180%] tracking-[0.04em] text-navy-900 ">
                        {event.interests}
                      </Typography> */}
                        <Typography
                          variant="body1"
                          fontSize={"12px"}
                          lineHeight={"180%"}
                          letterSpacing={"0.04em"}
                          className="py-1 line-clamp-1 text-start "
                        >
                          {event.newItemList
                            .map((item) => item.interestName)
                            .join(", ")}
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                ))}
              </Box>
              <Box className="mx-auto text-center md:py-2">
                <MHButton
                  onClick={() => handleClickOpen()}
                  className="md:text-[12px] leading-[102%] tracking-[0.05em] md:w-[150px] md:h-[50px] "
                >
                  See All Events
                </MHButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* TAB ENDS */}

      {/* MOBILE BEGINS  */}
      <LandingEventMobile />
      {/* MOBILE ENDS  */}
    </React.Fragment>
  );
};

export default LandingEvent;
