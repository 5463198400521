import { Box, Grid, Typography } from "@mui/material";
import React, { Fragment, useContext } from "react";

import { ReactComponent as Instagram } from "../../static/svg/instagram-white.svg";
import { ReactComponent as Facebook } from "../../static/svg/facebook-white.svg";
import { ReactComponent as LinkedIn } from "../../static/svg/linkedin-white.svg";
import { ReactComponent as Pinterest } from "../../static/svg/pinterest-white.svg";
import { ReactComponent as Twitter } from "../../static/svg/twitter-white.svg";
import { ReactComponent as SummitPlayIcon } from "../../static/svg/summit-play.svg";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import MHButton from "../Common/Button/MHButton";
import { SummitNavContext } from "../../store/context/summit-context";

type HeaderProps = {
  BgUrl: string;
  BgMobUrl: string;
  ResIconUrl: string;
  title: string;
  titleExt?: string;
  titleLocation?: string;
  titleInfo: string;
  pageInfo: string;
  pageInfoClassName: string;
  titleInfoclassName: string;
  headerClassName: string;
  majorClassName: string;
  boxClassName: string;
  podClassName?: string;
  podClassName2?: string;
  children: React.ReactNode;
  onClickReg?: (e: React.MouseEvent) => void;
  onClickVideo?: (e: React.MouseEvent) => void;
};

const SummitHeaderCard = (props: HeaderProps) => {
  let history = useHistory();

  const { path } = useRouteMatch();

  const handleClickOpen = () => {
    history.push(`/`);
  };

  const { active, setActive } = React.useContext(SummitNavContext);

  // const [open, setOpen] = React.useState(false);
  // const dataCtx = React.useContext(StaticDataContext);
  // const { openWaitlist, closeWaitlist } = dataCtx;

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  //   closeWaitlist();
  // };

  // React.useEffect(() => {
  //   if (openWaitlist) {
  //     handleOpen();
  //   }
  // }, [openWaitlist]);
  return (
    <Fragment>
      <Box className={props.headerClassName}>
        <Grid container spacing={{ xs: 1, sm: 1, md: 0 }}>
          <Grid item xs={12} md={7}>
            <img
              src={props.BgUrl}
              alt="Summit Image"
              style={{
                width: "100%",
                height: "100%",
              }}
              className="hidden lg:block lg:ml-64 lg:ml-[550px] lg:ml-0 md:transform md:-scale-x-100 lg:scale-100"
            />
          </Grid>

          <Box className="" sx={{}}>
            {props.children}
          </Box>

          <Grid item xs={12} md={5}>
            <Box className={props.majorClassName}>
              <Box
                // variant="body2"
                className="text-center w-[500px]"
                color="white"
                sx={{
                  fontWeight: 600,
                  fontSize: "12px",
                  fontFamily: "Area Normal",
                }}
              >
                <Box className={props.boxClassName} sx={{}}>
                  <img
                    src={props.ResIconUrl}
                    alt="Icon"
                    className=" mr-2 "
                    style={{
                      width: "43.3px",
                      height: "43.3px",
                    }}
                  />

                  <Box>
                    <Typography
                      variant="body2"
                      className="text-center mt-4 mx-auto leading-[102%] tracking-widest font-bold font-areaExt text-[12px]  uppercase"
                      color="white"
                    >
                      {props.title}
                    </Typography>
                  </Box>
                </Box>

                <Box className="md:flex absolute md:top-0 top-52 left-0 right-0 md:left-0 md:relative">
                  <Typography
                    variant="body2"
                    className=" text-center md:text-left md:pl-0 mt-16 md:mt-1 font-semibold font-areaSemi text-[13px] md:text-[18px] leading-[169%] tracking-[0.02em]"
                    color="white"
                  >
                    {props.titleExt}
                  </Typography>

                  <Typography
                    variant="body2"
                    className=" text-center md:text-left opacity-60 md:pl-2 mt-1 mb-6 font-semibold font-areaSemi text-[13px] md:text-[18px] leading-[169%] tracking-[0.02em]"
                    color="white"
                  >
                    {props.titleLocation}
                  </Typography>
                </Box>

                <Typography
                  variant="h3"
                  className={props.titleInfoclassName}
                  color="white"
                >
                  {props.titleInfo}
                </Typography>
                <Typography
                  variant="body2"
                  color="white"
                  className={props.pageInfoClassName}
                >
                  {props.pageInfo}
                </Typography>
              </Box>

              <Box className=" mt-12 hidden md:flex">
                {path.includes("recap-summit") ? (
                  <>
                    <Box className="pl-2 ">
                      <MHButton
                        onClick={props.onClickReg}
                        sx={{ width: "210px", height: "52px" }}
                        color="secondary"
                        className="text-xs"
                      >
                        {/* <SummitPlayIcon className="mr-2" /> */}
                        Watch Now
                      </MHButton>
                    </Box>
                    <Box className="pl-2 ">
                      <MHButton
                        onClick={props.onClickVideo}
                        sx={{ width: "210px", height: "52px" }}
                        color="primary"
                        className="text-xs"
                      >
                        {/* <SummitPlayIcon className="mr-2" /> */}
                        Sponsor Next Summit
                      </MHButton>
                    </Box>
                  </>
                ) : (
                  <>
                    {/* <a
                      className="nav-link no-underline "
                      href={`#registration`}
                      onClick={() => {
                        setActive("#registration");
                      }}
                    >
                      <Box
                        className="h-[52px] w-[150px] bg-yellow-100"
                        alignItems={"center"}
                        alignContent={"center"}
                        justifyContent={"center"}
                        display={"flex"}
                      >
                        <Typography
                          variant="subtitle2"
                          color="primary"
                          fontSize={{ xs: "12px", sm: "12px" }}
                          lineHeight={{ xs: "120%", sm: "102%" }}
                          letterSpacing={"0.05em"}
                          className=" capitalize h-fit md:px-4 mb-1"
                          justifyContent={{ xs: "center", sm: "center" }}
                        >
                          Watch Now
                        </Typography>
                      </Box>
                    </a> */}
                    <Box className="pl-2 ">
                      <MHButton
                        onClick={props.onClickReg}
                        sx={{ width: "210px", height: "52px" }}
                        color="secondary"
                        className="text-xs"
                      >
                        {/* <SummitPlayIcon className="mr-2" /> */}
                        Watch Now
                      </MHButton>
                    </Box>
                    <Box className="pl-2 ">
                      <MHButton
                        onClick={props.onClickVideo}
                        sx={{ width: "210px", height: "52px" }}
                        color="primary"
                        className="text-xs"
                      >
                        {/* <SummitPlayIcon className="mr-2" /> */}
                        {/* Sponsor The Summit */}
                        Sponsor Next Summit
                      </MHButton>
                    </Box>
                  </>
                )}
                {/* Web */}
              </Box>

              {/* Mobile */}
              <Box className=" mt-12 block md:hidden">
                {path.includes("recap-summit") ? (
                  <>
                    <Box className="pl-2 ">
                      <MHButton
                        onClick={props.onClickReg}
                        sx={{ width: "210px", height: "52px" }}
                        color="secondary"
                        className="text-xs"
                      >
                        {/* <SummitPlayIcon className="mr-2" /> */}
                        Watch Now
                      </MHButton>
                    </Box>
                    <Box className="pb-2 ">
                      <MHButton
                        onClick={props.onClickVideo}
                        sx={{ width: "210px", height: "52px" }}
                        color="primary"
                        className="text-xs"
                      >
                        <SummitPlayIcon className="mr-2" />
                        Sponsor Next Summit
                      </MHButton>
                    </Box>
                  </>
                ) : (
                  <>
                    {/* <a
                      className="nav-link no-underline "
                      href={`#registration`}
                      onClick={() => {
                        setActive("#registration");
                      }}
                    >
                      <Box
                        className="h-[52px] w-[210px] bg-yellow-100 mx-auto"
                        alignItems={"center"}
                        alignContent={"center"}
                        justifyContent={"center"}
                        display={"flex"}
                      >
                        <Typography
                          variant="subtitle2"
                          color="primary"
                          fontSize={{ xs: "12px", sm: "12px" }}
                          lineHeight={{ xs: "120%", sm: "102%" }}
                          letterSpacing={"0.05em"}
                          className=" capitalize h-fit md:px-4 mb-1"
                          justifyContent={{ xs: "center", sm: "center" }}
                        >
                          Register Now
                        </Typography>
                      </Box>
                    </a> */}
                    <Box className="pl-2 ">
                      <MHButton
                        onClick={props.onClickReg}
                        sx={{ width: "210px", height: "52px" }}
                        color="secondary"
                        className="text-xs"
                      >
                        {/* <SummitPlayIcon className="mr-2" /> */}
                        Watch Now
                      </MHButton>
                    </Box>

                    <Box className="pb-2 ">
                      <MHButton
                        onClick={props.onClickVideo}
                        sx={{ width: "210px", height: "52px" }}
                        color="primary"
                        className="text-xs"
                      >
                        <SummitPlayIcon className="mr-2" />
                        {/* Sponsor The Summit */}
                        Sponsor Next Summit
                      </MHButton>
                    </Box>
                  </>
                )}
              </Box>

              <Box className="block">
                {/* Web */}
                <Box className="mt-16 gap-6 hidden md:flex">
                  <a
                    className="cursor-pointer"
                    href="https://instagram.com/mhworklife?igshid=YmMyMTA2M2Y="
                    target="_blanc"
                    title="Visit our socials"
                  >
                    <Instagram color={"#fff"} />
                  </a>
                  <a
                    className="cursor-pointer"
                    href="https://www.facebook.com/motherhonestly?mibextid=LQQJ4d"
                    target="_blanc"
                    title="Visit our socials"
                  >
                    <Facebook />
                  </a>
                  <a
                    className="cursor-pointer"
                    href="https://www.linkedin.com/company/mhworklife/mycompany/"
                    target="_blanc"
                    title="Visit our socials"
                  >
                    <LinkedIn />
                  </a>
                  <a
                    className="cursor-pointer"
                    href="https://www.pinterest.com/motherhonestly/"
                    target="_blanc"
                    title="Visit our socials"
                  >
                    <Pinterest />
                  </a>
                  <a
                    className="cursor-pointer"
                    href="https://twitter.com/mhworklife?s=21&t=lhmzHgRp7adyrrMjPPyrsw"
                    target="_blanc"
                    title="Visit our socials"
                  >
                    <Twitter />
                  </a>
                </Box>

                {/* Mobile */}
                <Box className="mt-6 gap-6 place-content-center text-center flex md:hidden ">
                  <Link
                    className="cursor-pointer"
                    to={""}
                    title="Visit our socials"
                  >
                    <Instagram color={"#fff"} />
                  </Link>
                  <Link
                    className="cursor-pointer"
                    to={""}
                    title="Visit our socials"
                  >
                    <Facebook />
                  </Link>
                  <Link
                    className="cursor-pointer"
                    to={""}
                    title="Visit our socials"
                  >
                    <Pinterest />
                  </Link>
                  <Link
                    className="cursor-pointer"
                    to={""}
                    title="Visit our socials"
                  >
                    <Twitter />
                  </Link>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <img
          src={props.BgMobUrl}
          alt="Summit Image"
          style={{
            width: "100%",
            height: "100%",
          }}
          className="object-contain block md:hidden mt-3 md:mt-5"
        />
      </Box>
    </Fragment>
  );
};

export default SummitHeaderCard;
