import { Fragment } from "react";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import dayjs from "dayjs";
import Slider from "react-slick";

import { ReactComponent as BgOverlay } from "../../../static/svg/event.svg";
import {
  Box,
  Card,
  CardMedia,
  Grid,
  IconButton,
  Typography,
  Zoom,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import EventSort from "./EventSort";
import Pagination from "../../UI/Pagination";
import AllresHeader from "../SubComponents/AllresHeader";
import ResCard from "../SubComponents/ResCard";

import MHSubscribe from "../../Layout/MHSubscribe";
import MHFooter from "../../Layout/MHFooter";
import {
  ItemList,
  ResourceItemType,
} from "../../../models/resources.interface";
import { HttpResponse } from "../../../models/api.interface";
import {
  checkInvalidArray,
  compareStartTimeAsc,
  convertUTCToDateTime,
  convertUTCToTime,
} from "../../../utils/utils";
// import EventFilter from "./EventFilter";
import EventMobileFilter from "../Articles/ArticleMobileFilter";
import EventFilter from "./EventFilter";
import shuffleArray from "../../../hooks/use-random";
import useTitle from "../../../hooks/use-title";
import useAnalytics from "../../../hooks/use-analytics";

type ResProps = {
  thumbNailImageSrc?: string;
  itemList?: string[];
  title?: string;
  texts?: string;
  description?: string;
  categ?: string;
  id?: number;
  slug?: string;
  startTime?: string;
  createdDate?: string;
  updatedAt?: string;
};

const EventsPage = (props: ResProps) => {
  useTitle("Events");

  useAnalytics({
    pathname: window.location.pathname,
    pageTitle: "Events",
  });

  const theme = useTheme();
  const matchesMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const location = useLocation();
  const [resources, setResources] = useState<ResourceItemType[]>([]);
  let [count] = useState(0);

  var resUrl = `${process.env.REACT_APP_RES_EVENT_URL}`;

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(11);

  // Filtered resources using startTime
  const dateFilteredData = resources.sort(compareStartTimeAsc);

  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = dateFilteredData.slice(firstPostIndex, lastPostIndex);
  // console.log(currentPosts, "currentPosts");

  const [pastEvents, setPastEvents] = useState<ResourceItemType[]>([]);

  const [noOfElement, setnoOfElement] = useState(4);
  const slice = pastEvents.slice(0, noOfElement);

  if (dateFilteredData.length === 0) {
    console.log("erorr");
  }

  const getResource = async () => {
    try {
      const response = await fetch(resUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let jsonData: HttpResponse<ResourceItemType[]> = await response.json();

      const formattedResources = checkInvalidArray(jsonData.data)
        ? jsonData.data.map((dataItem) => {
            const priorityList: string[] = dataItem.priorities.split(",");
            let newItemList: ItemList[] = [];

            for (const item of priorityList) {
              const newObject = dataItem.itemList.find(
                (it: any) => it.id === +item
              );

              if (newObject) {
                if (!newItemList.length) {
                  newItemList = [newObject];
                } else {
                  newItemList = [...newItemList, newObject];
                }
              }
            }

            return {
              ...dataItem,
              newItemList,
            };
          })
        : [];

      setResources(formattedResources);
      // console.log(resources);
    } catch (err) {
      console.error(err);
    }
  };

  var pastUrl = `${process.env.REACT_APP_PAST_EVENT_URL}`;

  const getPastEvents = async () => {
    try {
      const response = await fetch(pastUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let jsonData: HttpResponse<ResourceItemType[]> = await response.json();

      const formattedResources = checkInvalidArray(jsonData.data)
        ? jsonData.data.map((dataItem) => {
            const priorityList: string[] = dataItem.priorities.split(",");
            let newItemList: ItemList[] = [];

            for (const item of priorityList) {
              const newObject = dataItem.itemList.find(
                (it: any) => it.id === +item
              );

              if (newObject) {
                if (!newItemList.length) {
                  newItemList = [newObject];
                } else {
                  newItemList = [...newItemList, newObject];
                }
              }
            }

            return {
              ...dataItem,
              newItemList,
            };
          })
        : [];

      // Function to filter the data according to vimeo
      // let filterKeyword = "vimeo";
      // const filtered = formattedResources.filter((item) =>
      //   item.ticketLink.toLowerCase().includes(filterKeyword.toLowerCase())
      // );

      // Function to filter the data according to vimeo, youtube, and linkedin.com/events/
      let filterKeywords = ["vimeo", "youtube", "linkedin.com/events/"];
      const filtered = formattedResources.filter((item) =>
        filterKeywords.some((keyword) =>
          item.ticketLink.toLowerCase().includes(keyword.toLowerCase())
        )
      );

      // Shuffle data to display randomly
      const randomizedData = shuffleArray(filtered); // Randomize the filtered array
      setPastEvents(randomizedData);

      // setPastEvents(formattedResources);
      // console.log(resources);
    } catch (err) {
      console.error(err);
    }
  };

  const settings = {
    centerMode: true,
    centerPadding: "22px",
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 800,
    autoplay: false,
    // autoplaySpeed: 1500,
    // beforeChange: (current, next) => setSlideIndex(next),
  };

  useEffect(() => {
    getResource();
    getPastEvents();
  }, []);

  return (
    <Fragment>
      <AllresHeader
        headerClassName="relative px-12 lg:px-0 text-center mx-auto w-full mx-auto md:pl-0 h-[450px] md:h-[450px] lg:h-auto bg-lilac-300 overflow-hidden"
        majorClassName="absolute top-14 md:top-12 md:inset-x-[18%] lg:top-24 lg:inset-x-[55%] text-center place-content-center"
        boxClassName="md:place-content-start pl-12 md:pl-0 ml-12 md:ml-0 place-content-start md:overscroll-x-hidden md:gap-2 flex md:pb-4"
        podClassName="hidden md:hidden"
        title="Events"
        titleInfo="from monthly workshops to yearly summits"
        titleExt="Find the Event for You"
        titleInfoclassName="md:text-left text-center my-6 w-[60%] md:w-full font-columbia text-4xl capitalize font-[500]"
        pageInfo="  Achieve your personal and professional goals with personalized
        solutions from our specialized experts in career, care, and wellbeing."
        eventSocialClassName="block"
        pageInfoClassName="font-semibold text-center md:text-left w-[70%] -ml-8 md:ml-0 md:w-full text-[14px] font-areaSemi"
        ResIconUrl="https://res.cloudinary.com/mother-honestly/image/upload/v1661645343/image_3_woz2ng.png"
        BgUrl="https://mh-resources-production.s3.us-west-1.amazonaws.com/SummitVector.png"
      >
        <BgOverlay
          className="overflow-hidden hidden md:block  absolute bottom-0 -right-20"
          height="300px"
          width="300px"
        />
        <BgOverlay
          className="overflow-hidden md:hidden  block absolute -top-28 -left-24 -scale-100"
          height="300px"
          width="300px"
        />
      </AllresHeader>
      {/* <EventSort resourcesLength={resources.length} /> */}

      {/* SEARCH FILTER */}
      <Typography
        color="primary"
        className=" lg:hidden block capitalize text-center pt-4 pb-2 opacity-50 text-[12px] font-areaSemi"
      >
        {resources.length} Results
      </Typography>

      {matchesMdDown ? (
        <EventMobileFilter />
      ) : (
        <EventFilter totalRecords={resources.length} />
      )}

      {/* SEARCH FILTER */}

      <>
        <Box className="mx-auto pt-10 bg-white px-4 md:px-20 pb-4">
          <Grid container spacing={2}>
            {currentPosts.map((res, index) => (
              <>
                <p className="hidden"> {(count = count + 1)}</p>

                {count === 1 ? (
                  <Grid item xs={12} md={12} lg={6}>
                    <Zoom in style={{ transitionDelay: "200ms" }}>
                      <Card
                        className={`relative w-auto h-[452px] object-cover bg-sky-400 rounded-md`}
                      >
                        {/* Overlay for Image */}
                        <Box className="bg-[#194049] h-full w-full object-cover absolute top-0 opacity-50 rounded-md"></Box>

                        <IconButton
                          disabled
                          className="absolute outline-none top-[6%] left-[6%]"
                        >
                          {/* Upcoming Event Icon */}
                          <img
                            className="h-6 w-6"
                            src="https://res.cloudinary.com/mother-honestly/image/upload/v1661645343/image_3_woz2ng.png"
                            alt="Resource Image"
                          />

                          <Typography className="ml-2 uppercase font-areaExt text-xs font-[900] leading-[102%] tracking-[0.1rem] text-white">
                            upcoming event
                          </Typography>
                        </IconButton>
                        <Box className="text-left w-full   absolute top-[20%] left-[6%]">
                          <Typography className=" capitalize line-clamp-2  w-4/5 font-columbia text-[28px] leading-[143%] tracking-[-0.04rem] font-[500] text-white">
                            {res.title}
                          </Typography>
                          <Typography className="pt-4 uppercase w-full font-areaExt text-[11px] font-[900] leading-[102%] tracking-[0.1rem] text-white">
                            {/* {dayjs(res.startTime!).format("MMMM D, h:mma")} */}
                            {convertUTCToDateTime(res.startTime)}
                          </Typography>

                          <Typography className="pt-4  w-3/4 font-areaSemi line-clamp-2 text-[15px] font-[700] leading-[200%] tracking-[0.02rem]  text-white">
                            {res.content}
                          </Typography>

                          <Box className="pt-12 space-x-4">
                            <Link
                              to={`${location.pathname}/${res.slug}/${res.id}
                      `}
                              className="bg-white w-[108px] no-underline px-6 py-4 h-[45px] text-navy-900 font-areaSemi not-italic text-[12px] font-[700] leading-[102%] tracking-[0.05rem]"
                            >
                              Reserve A Seat
                            </Link>
                            <Link
                              to={`/resources/videos
                      `}
                              target="_blanc"
                              className="bg-white w-[120px] no-underline px-8 py-4 h-[45px] text-navy-900 font-areaSemi not-italic text-[12px] font-[700] leading-[102%] tracking-[0.05rem]"
                            >
                              Watch Video
                            </Link>
                          </Box>
                        </Box>
                        <CardMedia
                          className="object-cover h-[452px]"
                          component="img"
                          image={res.thumbNailImageSrc}
                          // image="https://res.cloudinary.com/mother-honestly/image/upload/v1661545700/image_hsichu.png"
                          alt="Resource Image"
                        />
                      </Card>
                    </Zoom>
                  </Grid>
                ) : count >= 2 ? (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    lg={3}
                    key={index}
                    className="cardozo md:px-3 px-11 mt-6 md:mt-0"
                  >
                    <ResCard
                      cardClass="relative w-[305px] mb-10 h-[452px] object-cover bg-cream-100 rounded-md shadow-sm"
                      iconClass="hidden absolute top-10 ml-20 mt-12 w-20 h-20" //absolute top-10 ml-20 mt-12 w-20 h-20
                      imgBg="bg-pink-700 h-[305px]"
                      bodyBg="bg-cream-100"
                      top={convertUTCToDateTime(res.startTime)} // Use res.startTime directly
                      imageSrc={res.thumbNailImageSrc}
                      podTop="hidden"
                      title={res.title}
                      // category={res.itemList ? res.itemList[0].interestName : ""}
                      // categoryTwo={res.itemList ? res.itemList[1] : ""}
                      category={res.newItemList}
                      titleUrl={`/resources/events/${res.slug}/${res.id}`}
                      playUrl={`/resources/events/${res.slug}/${res.id}`}
                    />
                  </Grid>
                ) : (
                  ""
                )}
              </>
            ))}
          </Grid>
        </Box>

        <Pagination
          totalPosts={resources.length}
          postsPerPage={postsPerPage}
          onClick={() => {
            setCurrentPage(currentPage - 1);
          }}
          onClicked={() => {
            setCurrentPage(currentPage + 1);
          }}
          currentPage={currentPage}
        />

        {/* For Mobile */}
        <Box className="mx-auto pt-10 md:hidden block bg-white px-4 py-4 overflow-x-hidden">
          <Typography
            variant="h1"
            color="primary"
            className="font-areaSemi text-xl text-center py-12"
          >
            Past Events You Might Like
          </Typography>
          <Slider {...settings}>
            {pastEvents.map((res, index) => (
              <Grid item xs={12} md={6} lg={3} key={index}>
                <ResCard
                  cardClass="relative w-[305px] mb-10 h-[452px] object-cover bg-cream-100 rounded-md shadow-sm"
                  iconClass="absolute top-14 left-[38%] mt-12 w-20 h-20" //absolute top-10 ml-20 mt-12 w-20 h-20
                  imgBg="bg-pink-700 h-[305px]"
                  bodyBg="bg-cream-100"
                  top={dayjs(res.startTime!).format("MMMM D, YYYY")}
                  imageSrc={res.thumbNailImageSrc}
                  podTop="hidden"
                  // top={res.date}
                  title={res.title}
                  // category={res.itemList ? res.itemList[0].interestName : ""}
                  // categoryTwo={res.itemList ? res.itemList[1] : ""}
                  category={res.newItemList}
                  titleUrl={`/resources/past-events/${res.slug}/${res.id}`}
                  playUrl={`/resources/past-events/${res.slug}/${res.id}`}
                />
              </Grid>
            ))}
          </Slider>
        </Box>

        {/* For Web */}
        <Box className="mx-auto pt-10 hidden md:block bg-white px-12 md:px-20 py-4">
          <Typography
            variant="h1"
            color="primary"
            className="font-areaSemi text-xl text-center py-12"
          >
            Past Events You Might Like
          </Typography>
          <Grid container spacing={2}>
            {slice.map((res, index) => (
              <Grid item xs={12} sm={6} md={3} lg={3} key={index}>
                <ResCard
                  cardClass="relative w-[305px] mb-10 h-[452px] object-cover bg-cream-100 rounded-md shadow-sm"
                  iconClass="absolute top-14 left-[38%] mt-12 w-20 h-20" //absolute top-10 ml-20 mt-12 w-20 h-20
                  imgBg="bg-pink-700 h-[305px]"
                  bodyBg="bg-cream-100"
                  top={dayjs(res.startTime!).format("MMMM D, YYYY")}
                  imageSrc={res.thumbNailImageSrc}
                  podTop="hidden"
                  title={res.title}
                  // category={res.itemList ? res.itemList[0].interestName : ""}
                  // categoryTwo={res.itemList ? res.itemList[1] : ""}
                  category={res.newItemList}
                  titleUrl={`/resources/past-events/${res.slug}/${res.id}`}
                  playUrl={`/resources/past-events/${res.slug}/${res.id}`}
                />
              </Grid>
            ))}
          </Grid>
        </Box>

        <MHSubscribe />
        <MHFooter />
      </>
    </Fragment>
  );
};

export default EventsPage;
