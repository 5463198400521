import React, { Fragment, useEffect, useState } from "react";
import ViewHeader from "../SubComponents/ViewHeader";
import Typography from "@mui/material/Typography";
import { Box, Grid } from "@mui/material";
import ResCard from "../SubComponents/ResCard";
import { useHistory, useLocation, useParams } from "react-router-dom";
import MHButton from "../../Common/Button/MHButton";
import dayjs from "dayjs";
import Slider from "react-slick";

import MHSubscribe from "../../Layout/MHSubscribe";
import MHFooter from "../../Layout/MHFooter";
import {
  ItemList,
  ResourceItemType,
} from "../../../models/resources.interface";
import { HttpResponse } from "../../../models/api.interface";
import { checkInvalidArray, convertUTCToTime } from "../../../utils/utils";
import shuffleArray from "../../../hooks/use-random";
import useTitle from "../../../hooks/use-title";
import useAnalytics from "../../../hooks/use-analytics";

type ComponentProps = {
  thumbNailImageSrc?: string;
  itemList?: string[];
  startTime?: number;
  endTime?: number;
  time?: number;
  title?: string;
  texts?: string;
  content?: string;
  catchPhrase?: string;
  categ?: string;
  id?: number;
  slug?: string;
  createdDate?: string;
  updatedAt?: string;
};

const ViewEvent = (props: ComponentProps) => {
  useTitle('Events');

  useAnalytics({
    pathname: window.location.pathname,
    pageTitle: 'Events'
  });

  const location = useLocation();
  const [resources, setResources] = useState<ResourceItemType[]>([]);
  const [pastEvents, setPastEvents] = useState<ResourceItemType[]>([]);

  const [noOfElement, setnoOfElement] = useState(4);
  const slice = pastEvents.slice(0, noOfElement);

  var resUrl = `${process.env.REACT_APP_RES_EVENT_URL}`;

  let history = useHistory();

  const handleClickOpen = () => {
    history.push(`${location}/articles`);
  };

  const getResource = async () => {
    try {
      const response = await fetch(resUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let jsonData: HttpResponse<ResourceItemType[]> = await response.json();

      const formattedResources = checkInvalidArray(jsonData.data)
        ? jsonData.data.map((dataItem) => {
            const priorityList: string[] = dataItem.priorities.split(",");
            let newItemList: ItemList[] = [];

            for (const item of priorityList) {
              const newObject = dataItem.itemList.find(
                (it: any) => it.id === +item
              );

              if (newObject) {
                if (!newItemList.length) {
                  newItemList = [newObject];
                } else {
                  newItemList = [...newItemList, newObject];
                }
              }
            }

            return {
              ...dataItem,
              newItemList,
            };
          })
        : [];

      setResources(formattedResources);
      // console.log(resources);
    } catch (err) {
      console.error(err);
    }
  };

  var pastUrl = `${process.env.REACT_APP_PAST_EVENT_URL}`;

  const getPastEvents = async () => {
    try {
      const response = await fetch(pastUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let jsonData: HttpResponse<ResourceItemType[]> = await response.json();

      const formattedResources = checkInvalidArray(jsonData.data)
        ? jsonData.data.map((dataItem) => {
            const priorityList: string[] = dataItem.priorities.split(",");
            let newItemList: ItemList[] = [];

            for (const item of priorityList) {
              const newObject = dataItem.itemList.find(
                (it: any) => it.id === +item
              );

              if (newObject) {
                if (!newItemList.length) {
                  newItemList = [newObject];
                } else {
                  newItemList = [...newItemList, newObject];
                }
              }
            }

            return {
              ...dataItem,
              newItemList,
            };
          })
        : [];

      // Function to filter the data according to vimeo
      let filterKeyword = "vimeo";
      const filtered = formattedResources.filter((item) =>
        item.ticketLink.toLowerCase().includes(filterKeyword.toLowerCase())
      );

      // Shuffle data to display randomly
      const randomizedData = shuffleArray(filtered); // Randomize the filtered array
      setPastEvents(randomizedData);
      // setPastEvents(formattedResources);
      // console.log(resources);
    } catch (err) {
      console.error(err);
    }
  };

  const params = useParams<any>();
  // console.log(params.id!);

  const [data, setData] = useState<ResourceItemType | null>(null);

  var viewUrl = `${process.env.REACT_APP_ALL_RES_VIEW_URL}${params.id}`;

  const getData = async () => {
    try {
      const response = await fetch(viewUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const { data }: HttpResponse<ResourceItemType> = await response.json();

      const priorityList: string[] = data.priorities.split(",");
      let newItemList: ItemList[] = [];

      for (const item of priorityList) {
        const newObject = data.itemList.find((it) => it.id === +item);

        if (newObject) {
          if (!newItemList.length) {
            newItemList = [newObject];
          } else {
            newItemList = [...newItemList, newObject];
          }
        }
      }

      const formattedResource: ResourceItemType = {
        ...data,
        newItemList,
      };

      setData(formattedResource);
    } catch (err) {
      console.error("Cannot find Data");
    }
  };

  const settings = {
    centerMode: true,
    centerPadding: "22px",
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 800,
    autoplay: false,
    // autoplaySpeed: 1500,
    // beforeChange: (current, next) => setSlideIndex(next),
  };

  useEffect(() => {
    getResource();
    getPastEvents();
    getData();
  }, [params.id]);

  if (!data) {
    return null;
  }

  return (
    <Fragment>
      <ViewHeader
        titles={data.title}
        headerClassName="bg-lilac-300 w-full h-full px-4 md:px-0 md:pt-2 lg:pt-6 pt-6 md:pl-12 lg:pl-20 relative"
        description={data.content}
        headerDateClass="text-center hidden md:hidden mt-12 text-[12px] uppercase font-areaExt"
        imageUrl={data.thumbNailImageSrc}
        // imageUrl="https://res.cloudinary.com/mother-honestly/image/upload/v1661961579/image_1_ynjaab.png"
        imageClass="overflow-hidden h-full w-full right-0 absolute"
        categoryClassName="lg:bottom-2 md:-bottom-0 md:absolute md:w-2/3"
        category={data.newItemList}
        categoryOne={
          checkInvalidArray(data.newItemList).length
            ? data.newItemList[0].interestName
            : ""
        }
        downloadClassName="hidden flex -ml-4 my-6"
        podClassName="mt-10 flex gap-32 hidden"
        ticketClassName="pt-12 md:pt-2 lg:pt-20 text-center md:text-start"
        // SocialClassName="block"
        TabSocialClass="absolute gap-4 bottom-9 text-center place-content-center hidden md:flex lg:hidden md:right-4"
        ticketLink={data.ticketLink}
        date={dayjs(data.startTime).format("MMMM D ")}
        bottomDateClass="hidden"
        startTime={convertUTCToTime(data.startTime)}
        endTime={convertUTCToTime(data.endTime)}
        dateTwo={dayjs(data.startTime).format("MMMM D, YYYY")}
        dateClassName="text-center md:text-left pb-8 md:pb-0 md:w-3/4 text-xl md:text-[22px] font-areaSemi"
        episodeClassName="hidden"
        authorClassName="hidden"
        event
      />

      <Box className="px-8 md:px-20 lg:px-[300px] py-10 bg-white">
        <Typography
          variant="h3"
          color="primary"
          className="text-[22px] text-start font-areaSemi font-[600]"
        >
          {data.catchPhrase}
        </Typography>
        {/* <Typography
          variant="body2"
          color="primary"
          className="text-[16px] text-start py-12 leading-[200%] font-areaSemi"
        >
          {data.description}
        </Typography> */}

        {/* Event Description */}
        <div dangerouslySetInnerHTML={{ __html: data.description }} />

        <img
          src={data.source}
          alt="Resource Image"
          className="mx-auto bg-pink-700 w-full h-[257px] bodyImage object-cover lg:w-full lg:h-[630px] md:h-[456px]"
        />

        <Box className="flex  justify-center py-12">
          <MHButton
            onClick={() => {
              window.open(data.ticketLink);
            }}
            sx={{ width: "155px" }}
          >
            Reserve A Seat
          </MHButton>
        </Box>
      </Box>

      <Box className="bg-gray-300 h-[1px] w-[91.4%] opacity-50 overflow-hidden mx-auto"></Box>

      {/* For Mobile */}
      <Box className="mx-auto pt-10 md:hidden block bg-white px-4 py-4 overflow-x-hidden">
        <Typography
          variant="h1"
          color="primary"
          className="font-areaSemi text-xl text-center py-12"
        >
          Past Events You Might like
        </Typography>
        <Slider {...settings}>
          {pastEvents.map((res, index) => (
            <Grid item xs={12} md={6} lg={3} key={index}>
              <ResCard
                cardClass="relative w-[305px] mb-10 h-[452px] object-cover bg-cream-100 rounded-md shadow-sm"
                iconClass="absolute top-14 left-[38%] mt-12 w-20 h-20" //absolute top-10 ml-20 mt-12 w-20 h-20
                imgBg="bg-pink-700 h-[305px]"
                bodyBg="bg-cream-100"
                top={dayjs(res.startTime!).format("MMMM D, YYYY")}
                imageSrc={res.thumbNailImageSrc}
                podTop="hidden"
                // top={res.date}
                title={res.title}
                // category={res.itemList ? res.itemList[0].interestName : ""}
                // categoryTwo={res.itemList ? res.itemList[1] : ""}
                category={res.newItemList}
                titleUrl={`/resources/past-events/${res.slug}/${res.id}`}
                playUrl={`/resources/past-events/${res.slug}/${res.id}`}
              />
            </Grid>
          ))}
        </Slider>
      </Box>

      {/* For Web */}
      <Box className="mx-auto pt-10 hidden md:block bg-white px-12 md:px-20 py-4">
        <Typography
          variant="h1"
          color="primary"
          className="font-areaSemi text-xl text-center py-12"
        >
          Past Events You Might like
        </Typography>
        <Grid container spacing={2}>
          {slice.map((res, index) => (
            <Grid item xs={12} sm={6} md={3} lg={3} key={index}>
              <ResCard
                cardClass="relative w-[305px] mb-10 h-[452px] object-cover bg-cream-100 rounded-md shadow-sm"
                iconClass="absolute top-14 left-[38%] mt-12 w-20 h-20" //absolute top-10 ml-20 mt-12 w-20 h-20
                imgBg="bg-pink-700 h-[305px]"
                bodyBg="bg-cream-100"
                top={dayjs(res.startTime!).format("MMMM D, YYYY")}
                imageSrc={res.thumbNailImageSrc}
                podTop="hidden"
                title={res.title}
                // category={res.itemList ? res.itemList[0].interestName : ""}
                // categoryTwo={res.itemList ? res.itemList[1] : ""}
                category={res.newItemList}
                titleUrl={`/resources/past-events/${res.slug}/${res.id}`}
                playUrl={`/resources/past-events/${res.slug}/${res.id}`}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <MHSubscribe />
      <MHFooter />
    </Fragment>
  );
};

export default ViewEvent;
