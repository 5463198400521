import React, { Fragment } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
type Props = {};

const summitObj = [
  {
    caption: "Parents & Caregivers Solutions: ",
    info: "Discover innovative solutions and best practices to support working parents and employee caregivers, fostering a caring workplace that values family and caregiving responsibilities.",
  },
  {
    caption: "Caring and Inclusive Leadership: ",
    info: "Learn from top industry leaders and HR experts about the power of caring leadership in creating an inclusive work environment that empowers and motivates employees.",
  },
  {
    caption: "Caring Workplaces: ",
    info: "Explore strategies and insights to transform your workplace into a caring, empathetic space that prioritizes employee well-being, work-life balance, and mental health.",
  },
  {
    caption: "Enhance Productivity & Resilience: ",
    info: "Gain practical tools and resources to enhance productivity and resilience in your organization, aligning well-being with performance and success.",
  },
  {
    caption: "Care Solutions: ",
    info: "Discover solutions and brands dedicated to elevating care in the workplace and making a positive impact on the lives of employees and their families.",
  },
];

const summitAim = [
  {
    info: "Showcase FamTech Solutions: Present innovative technology solutions designed to support working parents and caregivers.",
  },
  {
    info: "Strengthen Resilience: Highlight the importance of building resilience and provide tools and strategies to do so in the workplace.",
  },
  {
    info: "Support Employee Resource Groups: Share effective strategies for nurturing and supporting Employee Resource Groups (ERGs).",
  },
  {
    info: "Highlight SMBs and Startups: Showcase how smaller businesses and startups are innovating in creating balanced workplaces.",
  },
  {
    info: "Foster Networking: Provide opportunities for attendees to connect, collaborate, and learn from one another, fostering a community dedicated to caring workplaces.",
  },
];

const SummitInfo = (props: Props) => {
  return (
    <Fragment>
      <section id="objectives" className="">
        {/* First */}
        <Box className=" lg:flex lg:justify-center lg:gap-10 ">
          <Box className="order-last md:h-[630px] md:w-[630px] h-[343px] w-[343px] mx-auto lg:mx-0">
            <img
              src="https://mh-resources-production.s3.us-west-1.amazonaws.com/care3.jpg"
              alt="Image"
              className=" rounded-[6px] object-contain md:mt-10"
            />
          </Box>
          <Box className="order-first text-center mx-auto lg:mx-0 w-[343px] md:h-[630px] md:w-[630px] md:mt-20 mt-10">
            <Box
              className="relative gap-0 md:-mt-10 md:pb-16 lg:pb-0 lg:mt-0"
              sx={{}}
            >
              <img
                src="https://res.cloudinary.com/mother-honestly/image/upload/v1672828059/summit-icon_iaakqk.png"
                alt="Icon"
                className="w-[29.6px] h-[29.6px] md:w-[43.3px] md:h-[43.3px] absolute md:top-0 left-24 md:left-[250px] lg:left-[250px]"
              />

              <Typography
                variant="caption"
                fontSize={{ xs: "11px", sm: "12px" }}
                lineHeight={{ xs: "230%", sm: "102%" }}
                letterSpacing={"0.1em"}
                className="uppercase text-center md:absolute md:top-4 lg:left-[300px]"
              >
                Events
              </Typography>
            </Box>

            <Typography
              variant="h1"
              color="primary"
              fontSize={{ xs: "38px", sm: "43px" }}
              lineHeight={{ xs: "120%", sm: "120.5%" }}
              letterSpacing={"-0.06em"}
              className="lg:mt-20 w-[300px] mx-auto mt-4 md:mt-0 md:w-full"
            >
              Care At Work Summit NYC
            </Typography>

            <Box className=" hidden md:flex my-6 place-content-center text-center">
              <Typography
                variant="body2"
                color="primary"
                fontSize={{ xs: "12px", sm: "18px" }}
                lineHeight={{ xs: "247%", sm: "169%" }}
                letterSpacing={"0.02em"}
                className=" "
              >
                October 26, 2023
              </Typography>

              <a
                onClick={() => {
                  window.open("https://goo.gl/maps/UwSKaF2ni4xpBCAj9");
                }}
                className="cursor-pointer"
                target="_blanc"
              >
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "12px", sm: "18px" }}
                  lineHeight={{ xs: "247%", sm: "169%" }}
                  letterSpacing={"0.02em"}
                  className=" opacity-60 pl-2 underline"
                >
                  Industry City, Brooklyn, NY
                </Typography>
              </a>
            </Box>

            {/* Mobile */}

            <Box className="block md:hidden my-6 place-content-center text-center">
              <Typography
                variant="body2"
                color="primary"
                fontSize={{ xs: "12px", sm: "18px" }}
                lineHeight={{ xs: "247%", sm: "169%" }}
                letterSpacing={"0.02em"}
                className=" "
              >
                October 26, 2023
              </Typography>

              <a
                onClick={() => {
                  window.open("https://goo.gl/maps/UwSKaF2ni4xpBCAj9");
                }}
                className="cursor-pointer"
                target="_blanc"
              >
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "12px", sm: "18px" }}
                  lineHeight={{ xs: "247%", sm: "169%" }}
                  letterSpacing={"0.02em"}
                  className=" opacity-60 pl-2"
                >
                  Industry City, Brooklyn, NY
                </Typography>
              </a>
            </Box>

            <Typography
              variant="body2"
              color="primary"
              fontSize={{ xs: "16px", sm: "22px" }}
              lineHeight={{ xs: "140%", sm: "140%" }}
              letterSpacing={"0.02em"}
              className="w-[335px] md:w-[440px] mx-auto"
            >
              We believe that elevating care within the workplace sparks
              innovation, enhances employee well-being, and propels business
              growth.
            </Typography>
            <Typography
              variant="body2"
              color="primary"
              fontSize={{ xs: "13px", sm: "14px" }}
              lineHeight={{ xs: "185%", sm: "180%" }}
              letterSpacing={"0.02em"}
              className="md:w-[560px] w-[300px] pb-10 mx-auto text-center mt-6"
            >
              MH WorkLife sits at the forefront of the movement of pioneers
              building care infrastructure for today's modern workforce. Through
              various solutions, and channels of engagement, we are in a unique
              position to connect and inspire a broad community of parents and
              caregivers, business and famtech leaders, and the broader society
              to elevate wellbeing, ignite innovation, and define the future of
              care at work.
            </Typography>
          </Box>
        </Box>

        {/* Second */}
        <Box className=" lg:flex lg:justify-center lg:gap-10 ">
          <Box className="order-first md:h-[630px] md:w-[630px] h-[343px] w-[343px] mx-auto lg:mx-0 ">
            <img
              src="https://mh-resources-production.s3.us-west-1.amazonaws.com/care2.jpg"
              alt="Image"
              className=" rounded-[6px] object-contain md:mt-10 lg:ml-16"
            />
          </Box>
          <Box className="order-last text-center mx-auto lg:py-5 w-[343px] md:h-[630px] md:w-[630px] md:mt-10 mt-10 mb-10 ">
            <Typography
              variant="h1"
              color="primary"
              fontSize={{ xs: "26px", sm: "32px" }}
              lineHeight={{ xs: "143%", sm: "120.5%" }}
              letterSpacing={"-0.04em"}
              className="w-[302px] md:w-[560px] mx-auto text-center"
            >
              Ensuring that the future of work works for parents, caregivers,
              Blacks, Latinos, LGBTQIA+, and employees of all kinds.
            </Typography>
            {/* <Typography
            variant="body2"
            color="primary"
            fontSize={{ xs: "13px", sm: "14px" }}
            lineHeight={{ xs: "185%", sm: "180%" }}
            letterSpacing={"0.02em"}
            className="md:w-[491px] w-[300px] mx-auto mt-6"
          >
            The summit will offer participants (employees and employers) an
            opportunity for dialogue. This is a great opportunity to hear
            first-hand experiences around current employee wellbeing challenges,
            discuss and contribute innovative ideas, and co-design workplace
            solutions that meet optimal performance and wellbeing measures.
          </Typography> */}

            <Box
              // variant="body2"
              color="primary"
              fontSize={{ xs: "13px", sm: "14px" }}
              lineHeight={{ xs: "185%", sm: "180%" }}
              letterSpacing={"0.02em"}
              className="md:w-[560px] w-[340px] pb-10 mx-auto text-start mt-6"
            >
              {summitObj.map((obj, index) => (
                <Box className="flex gap-2 py-2">
                  •
                  <Typography
                    variant="body2"
                    color="primary"
                    fontSize={{ xs: "13px", sm: "14px" }}
                    lineHeight={{ xs: "185%", sm: "180%" }}
                    letterSpacing={"0.02em"}
                    className=" mx-auto text-start"
                    key={index}
                  >
                    <b className="font-areaNorm"> {obj.caption} </b>
                    {obj.info}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </section>
    </Fragment>
  );
};

export default SummitInfo;
