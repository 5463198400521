import React, { Fragment } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import MHButton from "../Common/Button/MHButton";
import ParentsDialog from "./ParentsDialog";
import LeadersDialog from "./LeadersDialog";
import InvestorsDialog from "./InvestorsDialog";

type Props = {};

const SummitAttendee = (props: Props) => {
  const [openParents, setOpenParents] = React.useState(false);
  const [openLeaders, setOpenLeaders] = React.useState(false);
  const [openInvestors, setOpenInvestors] = React.useState(false);

  const handleOpenParents = () => {
    setOpenParents(true);
  };

  const handleCloseParents = () => {
    setOpenParents(false);
  };
  const handleOpenLeaders = () => {
    setOpenLeaders(true);
  };

  const handleCloseLeaders = () => {
    setOpenLeaders(false);
  };
  const handleOpenInvestors = () => {
    setOpenInvestors(true);
  };

  const handleCloseInvestors = () => {
    setOpenInvestors(false);
  };

  const CustomExpandIcon = () => {
    return (
      <Box
        sx={{
          ".Mui-expanded & > .collapsIconWrapper": {
            display: "none",
          },
          ".expandIconWrapper": {
            display: "none",
          },
          ".Mui-expanded & > .expandIconWrapper": {
            display: "block",
          },
        }}
      >
        <div className="expandIconWrapper">-</div>
        <div className="collapsIconWrapper">+</div>
      </Box>
    );
  };

  return (
    <Fragment>
      <section id="attendees" className="pt-8 md:pt-10">
        <Typography
          variant="h1"
          color="primary"
          fontSize={{ xs: "30px", sm: "43px" }}
          lineHeight={{ xs: "120%", sm: "120.5%" }}
          letterSpacing={"-0.06em"}
          className="mx-auto text-center mt-10"
        >
          Who should attend?
        </Typography>

        <Box className=" lg:flex gap-0 lg:px-14 place-content-center">
          <Box className="md:w-[401px] mx-auto mt-10">
            <img
              src="https://mh-resources-production.s3.us-west-1.amazonaws.com/caring-work-parent.png"
              alt="Image"
              style={{}}
              className="object-contain rounded-md h-[335px] w-[335px] mx-auto md:h-[401px] md:w-[401px]"
            />

            <Accordion className="shadow-none ">
              <AccordionSummary
                expandIcon={<CustomExpandIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="w-fit mx-auto"
              >
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "12px", sm: "16px" }}
                  lineHeight={{ xs: "200%", sm: "169%" }}
                  letterSpacing={"0.02em"}
                  className="text-center mx-auto mr-2"
                >
                  Working Parents & Caregivers
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="!mx-auto !place-content-center">
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "13px", sm: "14px" }}
                  lineHeight={{ xs: "200%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  className="text-center mx-auto py-6 w-[300px] md:w-[374px]  lg:h-[400px]"
                >
                  At the heart of this summit lies your everyday challenges and
                  triumphs. We understand the delicate juggling act you perform
                  daily, striving to balance work and caregiving
                  responsibilities. The Care At Work Summit is an opportunity
                  for you to gain practical strategies, access resources, and
                  connect with a supportive community. Learn from experts and
                  peers on how to manage stress, enhance productivity, and
                  advocate for yourself in the workplace. Our aim is to empower
                  you with the tools to achieve a fulfilling work-life balance.
                  <br />
                  <br />
                  Your company can support your registration to the conference.
                  <a
                    // href="CAW-Employee-Reimbursement.docx"
                    className="underline pl-1 cursor-pointer"
                    onClick={() => {
                      window.open(
                        "https://mh-resources-production.s3.us-west-1.amazonaws.com/Care+At+Work+Reimbursement+Form+(1).pdf"
                      );
                    }}
                  >
                    Download reimbursement template
                  </a>
                </Typography>

                <Box
                  className="relative items-center"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <MHButton
                    onClick={() => handleOpenParents()}
                    sx={{
                      width: "150px",
                      height: "40px",
                      fontSize: "12px",
                    }}
                  >
                    Learn more
                  </MHButton>
                </Box>
              </AccordionDetails>
            </Accordion>

            <Box className="bg-gray-300 h-[1px] w-[91.5%] my-7 mx-auto"></Box>
          </Box>
          <Box className=" md:w-[401px] mx-auto mt-10">
            <img
              src="https://res.cloudinary.com/mother-honestly/image/upload/v1672856429/Image_3_erw4fm.png"
              alt="Image"
              style={{}}
              className="object-contain rounded-md h-[335px] w-[335px] mx-auto md:h-[401px] md:w-[401px]"
            />

            <Accordion className="shadow-none">
              <AccordionSummary
                expandIcon={<CustomExpandIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="w-fit mx-auto"
              >
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "12px", sm: "16px" }}
                  lineHeight={{ xs: "200%", sm: "169%" }}
                  letterSpacing={"0.02em"}
                  className="text-center mx-auto mr-2"
                >
                  Workplace Leaders - HR, DEI, ESG & ERG
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="!mx-auto !place-content-center">
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "13px", sm: "14px" }}
                  lineHeight={{ xs: "200%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  className="text-center mx-auto py-6 w-[300px] md:w-[374px]  lg:h-[400px]"
                >
                  The role you play in shaping the working environment has never
                  been more critical. At the Care At Work Summit, we'll delve
                  into pressing issues such as flexibility, family leave,
                  inclusive leadership, and more. Learn how to foster a
                  supportive, inclusive work culture that values care,
                  diversity, and well-being. Discover innovative strategies and
                  best practices from industry leaders, and bring back valuable
                  insights that will help shape your organization's policies and
                  initiatives.
                </Typography>
                <Box
                  className="relative items-center"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <MHButton
                    onClick={() => handleOpenLeaders()}
                    sx={{
                      width: "150px",
                      height: "40px",
                      fontSize: "12px",
                    }}
                  >
                    Learn more
                  </MHButton>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Box className="bg-gray-300 h-[1px] w-[91.5%] my-7 mx-auto"></Box>
          </Box>
          <Box className="md:w-[401px] mx-auto mt-10">
            <img
              src="https://res.cloudinary.com/mother-honestly/image/upload/v1672856429/Image_2_xdv8h6.png"
              alt="Image"
              style={{}}
              className="object-contain rounded-md h-[335px] w-[335px] mx-auto md:h-[401px] md:w-[401px]"
            />

            <Accordion className="shadow-none ">
              <AccordionSummary
                expandIcon={<CustomExpandIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="w-fit mx-auto"
              >
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "12px", sm: "16px" }}
                  lineHeight={{ xs: "200%", sm: "169%" }}
                  letterSpacing={"0.02em"}
                  className="text-center mx-auto mr-2"
                >
                  Brands, Investors & Policy Leaders
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="!mx-auto !place-content-center">
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "13px", sm: "14px" }}
                  lineHeight={{ xs: "200%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  className="text-center mx-auto py-6 w-[300px] md:w-[374px]  lg:h-[400px]"
                >
                  As decision-makers, your influence extends far beyond the
                  immediate workplace. The Care At Work Summit is an opportunity
                  to engage with a receptive audience, showcase your commitment
                  to creating a caring, productive work environment, and align
                  your brand with a crucial cause. For investors and policy
                  leaders, it's a chance to gain insights into the future of
                  work, understand the needs of working parents and caregivers,
                  and drive change that benefits not just individual workplaces,
                  but society at large. <br />
                </Typography>
                <Box
                  className="relative items-center"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <MHButton
                    onClick={() => handleOpenInvestors()}
                    sx={{
                      width: "150px",
                      height: "40px",
                      fontSize: "12px",
                    }}
                  >
                    Partner with us
                  </MHButton>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Box className="bg-gray-300 h-[1px] w-[91.5%] my-7 mx-auto"></Box>
          </Box>
        </Box>
      </section>
      <ParentsDialog open={openParents} onClose={handleCloseParents} />
      <LeadersDialog open={openLeaders} onClose={handleCloseLeaders} />
      <InvestorsDialog open={openInvestors} onClose={handleCloseInvestors} />
    </Fragment>
  );
};

export default SummitAttendee;
