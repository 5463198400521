import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { Fragment, useEffect, useState } from "react";
import Slider from "react-slick";
import { CARE_SUMMIT_SPEAKERS_DATA } from "../../utils/landing-data";
import SpeakerCard from "./SpeakerCard";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../../firebase";
import { filterDataBySlug } from "../../utils/utils";

type Props = {
  name?: string;
  company?: string;
  slug?: string;
  title?: string;
  imgSrc?: string;
};

export default function SpeakersMobile() {
  const settings = {
    centerMode: true,
    centerPadding: "20px",
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 800,
    autoplay: true,
    autoplaySpeed: 1500,
  };

  const [summitSpeakerData, setSummitSpeakerData] = useState<Props[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const collectionRef = collection(db, "mh-summit-speakers-list");
        const q = query(collectionRef, orderBy("createdAt", "asc"));

        const querySnapshot = await getDocs(q);

        const data = querySnapshot.docs.map((doc) => ({
          ...(doc.data() as Props),
        }));

        const filteredData = filterDataBySlug(data, "care-at-work");
        setSummitSpeakerData(filteredData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Fragment>
      <section id="sponsors" className="">
        <Box className="block md:hidden bg-mhbluish overflow-x-hidden pt-10">
          <Typography
            variant="h2"
            fontSize={{ xs: "35px", sm: "40px", md: "44px" }}
            lineHeight={"120.5%"}
            letterSpacing={"-0.04em"}
            className="py-10 text-center "
          >
            Confirmed Speakers
          </Typography>
          <Typography
            variant="body1"
            fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
            lineHeight={{ xs: "170%", sm: "200%" }}
            letterSpacing={{ xs: "0.02em", sm: "0.02em" }}
            className="text-center pb-12 w-[375px] flex justify-center"
          >
            {/* MH WorkLife has made a distinctive mark by uniting a dynamic mix of
            parents, caregivers, executive workplace leaders, famtech pioneers,
            influential brands, and insightful investors. These individuals are
            the catalysts propelling innovative strides and redefining the
            future of work and centering care. */}
            More speakers to be announced soon
          </Typography>

          <Slider {...settings}>
            {summitSpeakerData.map((speaker, index) => (
              <Box key={index}>
                <SpeakerCard
                  name={speaker.name}
                  company={speaker.company}
                  title={speaker.title}
                  imgSrc={speaker.imgSrc}
                />
              </Box>
            ))}
          </Slider>
        </Box>
      </section>
    </Fragment>
  );
}
