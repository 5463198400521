import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import React from "react";

import { ReactComponent as YellowIcon } from "../../static/svg/eventyellow.svg";

type Props = {};

const SummitMotto = (props: Props) => {
  return (
    <React.Fragment>
      <Box className="bg-navy-900 h-[384px] lg:h-[306px]">
        <Box className="py-12 md:py-8 lg:py-12">
          <YellowIcon className="mx-auto h-8 w-8" />
          <Typography
            variant="h2"
            fontSize={{ xs: "24px", sm: "30px", md: "32px" }}
            lineHeight={"120.5%"}
            letterSpacing={"-0.04em"}
            className="py-10 lg:py-8 w-[88%] md:w-[75%] lg:w-[60%] text-center mx-auto"
            color={"white"}
            justifyContent="center"
            alignItems="center"
          >
            The Care At Work Summit is more than an event - it's a movement to
            transform workplaces into environments that prioritize the
            well-being of their employees. Join us as we shape our working lives
            together.
          </Typography>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default SummitMotto;
