import { SelectOption } from '@mui/base';

 export const Best_Description: SelectOption<string>[] = [
  {
    label: "Entrepreneur/ Founder/ C- Level",
    value: "Entrepreneur/ Founder/ C- Level",
  },
  {
    label: "CHRO/Chief People Officer",
    value: "CHRO/Chief People Officer",
  },
  {
    label: "DEI/ERG/People Professional",
    value: "DEI/ERG/People Professional",
  },
  {
    label: "Other",
    value: "Other",
  },
];

 export const Brand_Description: SelectOption<string>[] = [
  {
    label: "Investor",
    value: "Investor",
  },
  {
    label: "Policymaker",
    value: "Policymaker",
  },
  {
    label: "Famtech",
    value: "Famtech",
  },
  {
    label: "Brand",
    value: "Brand",
  },
 
];
 export const Group_Leaders: SelectOption<string>[] = [
  {
    label: "1-3",
    value: "1-3",
  },
  {
    label: "4-6",
    value: "4-6",
  },
  {
    label: "6-10",
    value: "6-10",
  },
  {
    label: "10+",
    value: "10+",
  },
  
];
 export const Topics_Areas: SelectOption<string>[] = [
  {
    label: "Care & Wellbeing Programs",
    value: "Care & Wellbeing Programs",
  },
  {
    label: "Flexibility & Distributed Work",
    value: "Flexibility & Distributed Work",
  },
  {
    label: "Diversity, Equity & Inclusion",
    value: "Diversity, Equity & Inclusion",
  },
  {
    label: "Caring Leadership & Workplaces",
    value: "Caring Leadership & Workplaces",
  },
  {
    label: "Managing Parental Leave",
    value: "Managing Parental Leave",
  },
  {
    label: "Childcare & Eldercare Support",
    value: "Childcare & Eldercare Support",
  },
  {
    label: "Caregiver Retention Strategies",
    value: "Caregiver Retention Strategies",
  },
  {
    label: "WorkLife Solutions",
    value: "WorkLife Solutions",
  },
  
];

  export const SUMMIT_AGENDA = [
    {
      time: '8:30am',
      title: 'Coffee & Registration',
      info: 'Fuel up and get ready for a day of transformative insights and connections.',
      icon: '',

    },
    {
      time: '9:15 am',
      title: 'Unlocking Care Solutions:',
      info: 'Managers and leaders, gear up for a hands-on workshop that unlocks powerful care strategies.',
      icon: '',

    },
    {
      time: '10:00 am',
      title: 'The State of Care at Work and The Case For Caring Corporations:',
      info: `Join us for an eye-opening keynote on the State of Care at Work and why it's crucial for the future.`,
      icon: '',

    },
    {
      time: '10:15 am',
      title: 'Care Innovation:',
      info: `Famtech leaders take you through innovative ways to build a caring and inclusive workplace.`,
      icon: '',

    },
    {
      time: '10:45 am',
      title: 'Leading With Care:',
      info: 'Discover the cutting-edge principles reshaping leadership, emphasizing care as a cornerstone.',
      icon: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/greenhouse.png',

    },
  
    {
      time: '11:15 am',
      title: 'Caring Policies in Action:',
      info: `Be part of the change—explore how family-friendly policies are making their way from Capitol Hill to your office.`,
      icon: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/moms-rising.png',

    },
    {
      time: '11:45 am',
      title: 'ChildCare Crisis & The Workplace:',
      info: 'Explore innovative solutions to make childcare work seamlessly for working parents.',
      icon: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/vivmom-1.png',

    },
    {
      time: '12:15 pm',
      title: 'Lunch & Roundtable Conversations',
      info: '',
      icon: '',

    },
    {
      time: '1:00 pm',
      title: 'Reimagining The Pause: ',
      info: 'Gain insights into how we can bridge the gap when caregivers temporarily or permanently leave the workforce.',
      icon: '',

    },
    {
      time: '1:30 pm',
      title: 'Giving Back At Work:',
      info: 'Uncover the value of giving back in the workplace.',
      icon: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/happyly-summit.png',
      

    },
    {
      time: '2:00 pm',
    title: 'Reimagining Employee Leave: ',
      info: 'Learn how to elevate care, from welcoming a new family member to saying goodbye to a loved one.',
      icon: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/clb3.png',
    },
  
    {
      time: '2:30 pm',
      title: 'The Cost of Care: ',
      info: `Delve into the financial, health, and wellbeing impacts of caregiving in today's workplace.`,
      icon: '',

    },
    {
      time: '3:00 pm',
      title: 'Coffee Break',
      info: '',
      icon: '',

    },
    {
      time: '3:15 pm',
      title: 'Workforce Equity:',
      info: 'Indeed presents the future of inclusive work design that benefits everyone.',
      icon: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/indeed-1.png',

    },
    {
      time: '3:45 pm',
      title: 'Equity in Care and Wellbeing:',
      info: 'Dive into the essential discussion of how to bridge care gaps for all workers.',
      icon: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/Mirza_Logo.png',

    },
    {
      time: '4:15 pm',
      title: 'Caring Workplaces & Caring Leaders Award:',
      info: 'Celebrate the organizations and leaders driving caring workplaces.',
      icon: '',

    },
    {
      time: '4:30 pm',
      title: 'Caring Forward: The Future Of Workplace Care:',
      info: `“Caring Forward” is not just a closing keynote; it's a call to action. Join us and be a driving force in the future of care at work.`,
      icon: '',

    },
    
    
  ];


  export const CORE_PROGRAMMING = [
    {
     title: 'Productivity',
      info: 'Explore how fostering a caring and inclusive work environment can drive productivity. We’ll delve into strategies that focus on the well-being of employees, promoting a healthy work-life balance that boosts efficiency and reduces burnout.',
    },
    {
      title: 'Flexibility',
      info: 'Investigate the critical role of flexible work policies in modern workplaces. We’ll delve into best practices for implementing flexible hours, remote work, and other policies that accommodate diverse employee needs.',
    },
    {
      title: 'Caregiving',
      info: 'This topic focuses on the dual roles many employees shoulder as professionals and caregivers. We’ll discuss how employers can support these employees, acknowledging their unique challenges and offering solutions for a more harmonious balance.',
    },
    {
      title: 'Rest, Renewal & Restoration',
      info: 'Dive into the crucial role of downtime in maintaining a healthy workforce. We’ll discuss the science behind rest and rejuvenation, highlighting practical strategies for companies to foster a culture that values work-life balance.',
    },
    {
      title: 'Work/Care InfrastructureThe Impact Of The Economy On Our Paycheck, Performance, And Productivity',
      info: 'Discover the systems that organizations can put in place to support a balance between work and care responsibilities. We’ll explore on-site childcare, partnerships with caregiving services, and more.',
    },
    {
      title: 'Workplace Leadership',
      info: 'Learn about the key leadership traits needed to create caring, inclusive workplaces. This topic will cover empathy, active listening, and other soft skills, as well as strategic decision-making around policies that affect employee well-being.',
    },
    {  
      title: 'Family Leave, Return-To-Work',
      info: 'Discuss the importance of comprehensive family leave policies and supportive return-to-work programs. We’ll look at successful case studies and offer practical tips for implementation.',
    },
    {
      title: 'Employee Resource Groups',
      info: 'Understand the role of ERGs in creating inclusive and supportive workplaces. We’ll share strategies for fostering active, effective ERGs that can provide valuable insights to leadership.',
    },
    {
      title: 'Family / Tech Solutions',
      info: 'Explore cutting-edge technology designed to support working parents and caregivers. From apps that help manage caregiving tasks to platforms that connect families with resources, we’ll look at how tech is changing the game.',
    },
    {
      title: 'Policy Advocacy',
      info: 'Learn about the role of businesses in advocating for employees in the broader community. This could include lobbying for policies that support work-life balance, participating in local events, or partnering with other organizations for employee support.',
    },
    {
      title: 'Caregiving Finance',
      info: 'Dive into the financial implications of caregiving. We’ll cover employer-sponsored benefits and other financial support systems that can relieve the monetary stress often associated with caregiving.',
    },
    {
      title: 'Stress, Burnout & Mental Health',
      info: 'Delve into the mental health challenges that can arise from juggling work and caregiving responsibilities. We’ll offer strategies for recognizing signs of burnout and stress, and discuss how employers can create mental health support systems in the workplace.',
    },
    
    
  ];


export const SUMMIT_HeaderData =  [
  {
    title: "The Summit",
    linkTo: "aboutsummit",
  },
  {
    title: "Objectives",
    linkTo: "objectives",
  },
  {
    title: "Attendee",
    linkTo: "attendees",
  },
  {
    title: "Agenda",
    linkTo: "agenda",
  },
  {
    title: "Registration",
    linkTo: "registration",
  },
  
  {
    title: "Speakers",
    linkTo: "speakers",
  },
 
  {
    title: "Sponsors",
    linkTo: "eventsponsors",
  },
  {
    title: "FAQ",
    linkTo: "summitfaq",
  },
  
];

  export const DIGITAL = [
    {
      description: 'All Access To Hands On Keynotes, Panels, Workshop & Roundtable Discussions ',
    },
    
  ];

  export const IN_PERSON = [
    {
      description: 'All Access To Hands On Keynotes, Panels, Workshop & Roundtable Discussions ',
    },
    {
      description: 'Small group sessions and breakouts for parents, caregivers and employees of all kinds',
    },
    {
      description: 'Small group sessions and roundtable discussions for managers, HR leaders, benefit providers, care innovators, and more.',
    },
    {
      description: 'Mentor sessions to gain strategic insights from leaders in the parenting and caregiving ',
    },
    // {
    //   description: 'Dedicated 1:1 guidance and consultations you can book in advance to secure quality time with experts and consultants to answer your burning caregiving, career, and wellbeing questions',
    // },
    {
      description: 'Self-care experiences throughout the day in the form of wellness services and treatments',
    },
    {
      description: 'Happy Hour & Networking opportunities to engage and connect with other members of the MH community in the parenting and caregiving space',
    },
    {
      description: 'A gift bag full of goodies and wellness products for you to take home      ',
    },
    {
      description: 'Healthy food and functional beverage options to keep you fueled throughout the day      ',
    },
    
  ];

  export const MANAGER_PASS = [
    {
      description: 'All Access To Hands On Keynotes, Panels, Workshop & Roundtable Discussions ',
    },
    {
      description: 'Small group sessions and breakouts for parents, caregivers and employees of all kinds',
    },
    {
      description: 'Interactive workshops for CHROs, DEIB, ESG, benefit providers, care innovators, and more.      ',
    },
    {
      description: 'Inclusive and Caring leadership sessions to gain strategic insights from thought-leaders and innovators in the parenting and caregiving space       ',
    },
    {
      description: 'Welcome Reception & Introduction to famtech and care innovators who are building sustainable solutions for best-in-class organizations      ',
    },
    // {
    //   description: 'Dedicated 1:1 guidance and consultations you can book in advance to secure quality time with experts and consultants to answer your burning caregiving, career, and wellbeing questions      ',
    // },
    {
      description: 'Self-care experiences throughout the day in the form of wellness services and treatments      ',
    },
    {
      description: 'Happy Hour & Networking opportunities to engage and connect with other members of the MH community in the parenting and caregiving space      ',
    },
    {
      description: 'A gift bag full of goodies and wellness products for you to take home      ',
    },
    {
      description: 'Healthy food and functional beverage options to keep you fueled throughout the day      ',
    },
    
  ];

  export const INCLUSIVE = [
    {
      description: 'All Access To Hands On Keynotes, Panels, Workshop & Roundtable Discussions ',
    },
    {
      description: 'Welcome Reception with care leaders, investors, enterprise HR & DEI leaders',
    },
    {
      description: 'Gift Bags filled with care items from  our brand partners',
    },
    {
      description: 'Full breakfast and lunch, with beverages and refreshments throughout the day',
    },
    {
      description: 'Small group sessions and breakouts for parents, caregivers and employees of all kinds',
    },
    {
      description: 'Small group sessions and roundtable discussions for managers, HR leaders, benefit providers, care innovators, and more.',
    },
    {
      description: 'Resilience Retreat: Renewing Work-Life Energies',
    },
    
  ];

  export const SUMMIT_PRICING = [
    {
      title: 'Digital',
      price: '$99',
      caption: '',
      url: 'https://hopin.com/events/mhworklife-care-at-work-summit/registration',
      info: DIGITAL,
    },
    {
      title: 'In-Person',
      price: '$399',
      caption: '',
      url: 'https://buy.stripe.com/6oEg1BafbbyEaOI006',
      info: IN_PERSON,
    },
    {
      title: 'Manager Pass',
      price: '$499',
      caption: '',
      url: 'https://buy.stripe.com/8wM6r11IF0U06ys5kr',
      info: MANAGER_PASS,
    },
    // {
    //   title: 'All Inclusive + Next Day Retreat',
    //   price: '$999',
    //   caption: '',
    //   url: 'https://buy.stripe.com/bIY02D9b7auA8GAdQV',
    //   info: INCLUSIVE,
    // },
    
  ];



  export const PRESS_MEDIA_DATA = [
    {
      title: 'Wall Street Journal',
      text: 'Parental Overload? These Two Moms Are Working on Tech Cures',
      link: 'https://www.wsj.com/articles/parental-overload-these-two-moms-are-working-on-tech-cures-11592299801',
      image: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/Wall+Street+Jounal.png',
    },
    {
      title: 'Forbes',
      text: 'A Future Of Work That Includes Care Starts With The Care Wallet',
      link: 'https://www.forbes.com/sites/amyshoenthal/2022/11/16/a-future-of-work-that-includes-care-starts-with-a-work-life-wallet-to-support-individual-employee-needs/?sh=5ac2ab087cb8',
      image: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/Forbes.png',
    },
    {
      title: 'Techcrunch',
      text: 'MH’s New Offering Aims To Give Employees More Freedom When It Comes To Caregiving Spend',
      link: 'https://techcrunch.com/2022/10/18/mother-honestlys-new-commerce-offering-aims-to-give-employees-more-freedom-when-it-comes-to-caregiving-spend/',
      image: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/Tech+crunch.png',
    },
    {
      title: 'Fast Company',
      text: 'Doing It All Is A Productivity Lie',
      link: 'https://www.fastcompany.com/90702605/we-need-to-dismantle-the-productivity-lie-that-demands-we-do-it-all',
      image: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/Fast+Company.png',
    },
    {
      title: 'Time.com',
      text: 'Remote Work Is Helping Dads Do More At Home. Let’s Make Sure It Stays That Way',
      link: 'https://time.com/charter/6241062/remote-work-is-helping-dads-do-more-at-home-lets-make-sure-it-stays-that-way/',
      image: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/Times.png',
    },
    {
      title: 'Fortune',
      text: 'Remote work may level the playing field and ensure women aren’t forced to “walk on eggshells”',
      link: 'https://fortune.com/2022/11/03/remote-work-may-level-the-playing-field-for-working-moms/',
      image: 'https://mh-resources-production.s3.us-west-1.amazonaws.com/Fortune.png',
    },
    
    
  ];