import { ReactComponent as CareerSVG } from '../static/svg/interest/career.svg';
import { ReactComponent as FamilySVG } from '../static/svg/interest/family.svg';
import { ReactComponent as FinanceSVG } from '../static/svg/interest/finances.svg';
import { ReactComponent as HouseholdSVG } from '../static/svg/interest/household.svg';
import { ReactComponent as WellbeingSVG } from '../static/svg/interest/wellbeing.svg';

export const Resources_Categ = [
  {
    label: 'Career',
    value: 'Career',
    imgSrc:
      'https://res.cloudinary.com/mother-honestly/image/upload/v1670794274/Rectangle_axbxnk.png'
  },
  {
    label: 'Caregiving',
    value: 'Caregiving',
    imgSrc:
      'https://res.cloudinary.com/mother-honestly/image/upload/v1670794857/Rectangle_jhy0tm.png'
  },
  {
    label: 'Lifestyle',
    value: 'Lifestyle',
    imgSrc:
      'https://res.cloudinary.com/mother-honestly/image/upload/v1670794857/Rectangle_1_goumdj.png'
  },
  {
    label: 'Finances',
    value: 'Finances',
    imgSrc:
      'https://res.cloudinary.com/mother-honestly/image/upload/v1670794857/Rectangle_2_zezneg.png'
  },
  {
    label: 'Wellbeing',
    value: 'Wellbeing',
    imgSrc:
      'https://res.cloudinary.com/mother-honestly/image/upload/v1670794857/Rectangle_3_xumhdi.png'
  },
  // {
  //   label: 'Parental Leave',
  //   value: 'Parental Leave',
  //   imgSrc:
  //     'https://res.cloudinary.com/mother-honestly/image/upload/v1670794274/Rectangle_axbxnk.png'
  // },
  // {
  //   label: 'Child Care',
  //   value: 'Child Care',
  //   imgSrc:
  //     'https://res.cloudinary.com/mother-honestly/image/upload/v1670794857/Rectangle_jhy0tm.png'
  // },
  // {
  //   label: 'Elder Care',
  //   value: 'Elder Care',
  //   imgSrc:
  //     'https://res.cloudinary.com/mother-honestly/image/upload/v1670794857/Rectangle_1_goumdj.png'
  // },
  // {
  //   label: 'Single & Co-Parenting',
  //   value: 'Single & Co-Parenting',
  //   imgSrc:
  //     'https://res.cloudinary.com/mother-honestly/image/upload/v1670794857/Rectangle_2_zezneg.png'
  // },
  // {
  //   label: 'Separation & Divorce',
  //   value: 'Separation & Divorce',
  //   imgSrc:
  //     'https://res.cloudinary.com/mother-honestly/image/upload/v1670794857/Rectangle_3_xumhdi.png'
  // },
  // {
  //   label: 'Managing Teenagers',
  //   value: 'Managing Teenagers',
  //   imgSrc:
  //     'https://res.cloudinary.com/mother-honestly/image/upload/v1670794274/Rectangle_axbxnk.png'
  // },
  // {
  //   label: 'Household Expenses',
  //   value: 'Household Expenses',
  //   imgSrc:
  //     'https://res.cloudinary.com/mother-honestly/image/upload/v1670794857/Rectangle_jhy0tm.png'
  // },
  // {
  //   label: 'Equal Partnership',
  //   value: 'Equal Partnership',
  //   imgSrc:
  //     'https://res.cloudinary.com/mother-honestly/image/upload/v1670794857/Rectangle_1_goumdj.png'
  // },
  // {
  //   label: 'Work-Life Integration',
  //   value: 'Work-Life Integration',
  //   imgSrc:
  //     'https://res.cloudinary.com/mother-honestly/image/upload/v1670794857/Rectangle_2_zezneg.png'
  // },
  // {
  //   label: 'Mental Health & Wellbeing',
  //   value: 'Mental Health & Wellbeing',
  //   imgSrc:
  //     'https://res.cloudinary.com/mother-honestly/image/upload/v1670794857/Rectangle_3_xumhdi.png'
  // },
  // {
  //   label: 'Inclusion At Work',
  //   value: 'Inclusion At Work',
  //   imgSrc:
  //     'https://res.cloudinary.com/mother-honestly/image/upload/v1670794857/Rectangle_3_xumhdi.png'
  // },
  // {
  //   label: 'Family Building',
  //   value: 'Family Building',
  //   imgSrc:
  //     'https://res.cloudinary.com/mother-honestly/image/upload/v1670794274/Rectangle_axbxnk.png'
  // },
  // {
  //   label: 'Period & Menopause',
  //   value: 'Period & Menopause',
  //   imgSrc:
  //     'https://res.cloudinary.com/mother-honestly/image/upload/v1670794857/Rectangle_jhy0tm.png'
  // },
  // {
  //   label: 'Time Management & Productivity',
  //   value: 'Time Management & Productivity',
  //   imgSrc:
  //     'https://res.cloudinary.com/mother-honestly/image/upload/v1670794857/Rectangle_1_goumdj.png'
  // },
  // {
  //   label: 'Career Coaching',
  //   value: 'Career Coaching',
  //   imgSrc:
  //     'https://res.cloudinary.com/mother-honestly/image/upload/v1670794857/Rectangle_2_zezneg.png'
  // },
  // {
  //   label: 'Household Chores',
  //   value: 'Household Chores',
  //   imgSrc:
  //     'https://res.cloudinary.com/mother-honestly/image/upload/v1670794857/Rectangle_3_xumhdi.png'
  // },
  // {
  //   label: 'Meal & Nutrition',
  //   value: 'Meal & Nutrition',
  //   imgSrc:
  //     'https://res.cloudinary.com/mother-honestly/image/upload/v1670794857/Rectangle_1_goumdj.png'
  // },
  // {
  //   label: 'Flexible Work',
  //   value: 'Flexible Work',
  //   imgSrc:
  //     'https://res.cloudinary.com/mother-honestly/image/upload/v1670794857/Rectangle_2_zezneg.png'
  // },
  // {
  //   label: 'Advocacy At Work',
  //   value: 'Advocacy At Work',
  //   imgSrc:
  //     'https://res.cloudinary.com/mother-honestly/image/upload/v1670794274/Rectangle_axbxnk.png'
  // }
];

// const LandingResCategory = (props: Props) => {

export const InterestSVG_Categ = [
  {
    label: 'Career',
    value: 'Career',
    imgSvg: CareerSVG
  },
  {
    label: 'Family',
    value: 'Family',
    imgSvg: FamilySVG
  },
  {
    label: 'Household',
    value: 'Household',
    imgSvg: HouseholdSVG
  },
  {
    label: 'Finances',
    value: 'Finances',
    imgSvg: FinanceSVG
  },
  {
    label: 'Wellbeing',
    value: 'Wellbeing',
    imgSvg: WellbeingSVG
  }
];
